import React, {useEffect, useState} from 'react'
import './ShoppingListIndicator.scss'
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted"
import {useAppSelector} from "../../redux/hooks"
import {getPriceSum} from "../../redux/shoppingListSlice"
import {Badge} from "@mui/material"

export default function ShoppingListIndicator() {
    const price = useAppSelector((state) => getPriceSum(state))
    const [fullScale, setFullScale] = useState(true)

    useEffect(() => {
        const handleMediaQueryChange = (event: any) => {
            setFullScale(!event.matches)
        }

        const mediaQuery = window.matchMedia('(max-width: 800px)')

        mediaQuery.addEventListener('change', handleMediaQueryChange)

        // init
        setFullScale(!mediaQuery.matches)

        return () => {
            mediaQuery.removeEventListener('change', handleMediaQueryChange)
        };
    }, [])

    return fullScale ? (
        <div className="shopping-list-indicator">
            <div className="icon-wrapper">
                <FormatListBulletedIcon className="icon"/>
            </div>
            <div className="sum">{price.toFixed(2)} лв</div>
        </div>
    ) : (
        <div className="shopping-list-indicator shrinked">
            <div className="icon-wrapper">
                <Badge badgeContent={price} max={1000} color="primary">
                    <FormatListBulletedIcon className="icon"/>
                </Badge>
            </div>
        </div>
    )
}
