import React, {useState} from "react"
import './MarketSelector.scss'
import LocalGroceryStoreRoundedIcon from '@mui/icons-material/LocalGroceryStoreRounded'
import {
    Button, Chip,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    MenuItem, Select, SelectChangeEvent,
} from "@mui/material"
import {useAppDispatch, useAppSelector} from "../../../redux/hooks"
import {getSelectedMarkets, setSelectedMarkets} from "../../../redux/configSlice"
import CheckIcon from '@mui/icons-material/Check'
import ClearIcon from '@mui/icons-material/Clear'
import Box from "@mui/material/Box"
import DialogTransitionDown from "../../common/DialogTransitionDown"
import {getMarketById, getMarketByName, useMarkets} from "../../../contexts/BasicData"


export default function MarketSelector() {
    const markets = useMarkets()
    const marketIds = useAppSelector((state) => getSelectedMarkets(state))
    const dispatch = useAppDispatch()

    const [open, setOpen] = useState(false)
    const [currentlySelectedMarkets, setCurrentlySelectedMarkets] = useState<number[]>(marketIds);

    const close = () => {
        setCurrentlySelectedMarkets(marketIds)
        setOpen(false)
    }
    const submit = () => {
        setOpen(false)
        dispatch(setSelectedMarkets(currentlySelectedMarkets))
    }

    const handleChange = (event: SelectChangeEvent<string[]>) => {
        const {
            target: { value },
        } = event

        // On autofill, we get a string value
        const rawValues = typeof value === 'string' ? value.split(',') : value
        const values = rawValues.map(v => getMarketByName(v, markets).id)

        setCurrentlySelectedMarkets(values)
    }

    const marketsOptions = []
    for (let market of markets) {
        marketsOptions.push(<MenuItem key={market.id} value={market.name}>{market.name}</MenuItem>)
    }

    return (
        <div>
            <div className="button market" onClick={() => setOpen(true)}>
                <LocalGroceryStoreRoundedIcon className="icon"></LocalGroceryStoreRoundedIcon>
                <div className="text">Магазини</div>
            </div>
            <Dialog
                open={open}
                TransitionComponent={DialogTransitionDown}
                keepMounted
                onClose={close}
                aria-describedby="alert-dialog-slide-description"
            >
                <div className="market-selector-dialog">
                    <DialogTitle>Избор на магазини</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-slide-description">
                            Изберете магазините, за които искате да виждате актуални цени. Можете да избирате само измежду магазини, които са налични в избрания от Вас град.
                        </DialogContentText>
                        <Select
                            className="selector"
                            multiple
                            value={currentlySelectedMarkets.map(id => getMarketById(id, markets).name)}
                            onChange={handleChange}
                            displayEmpty
                            renderValue={(selected) => (
                                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                    {selected.map((value: string) => (
                                        <Chip key={getMarketByName(value, markets).id} label={value} />
                                    ))}
                                </Box>
                            )}>
                            {marketsOptions}
                        </Select>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="outlined" color="error" startIcon={<ClearIcon />} onClick={close}>Отказ</Button>
                        <Button variant="contained" color="success" startIcon={<CheckIcon />} onClick={submit}>Потвърди</Button>
                    </DialogActions>
                </div>
            </Dialog>
        </div>
    )
}