type EndpointsList = {
  search: string,
  market_getAll: string,
  category_getAll: string,
  category_get: string,
  category_getFullHierarchy: string,
  baseProduct_get: string,
  product_get: string,
  product_getMultipleByCategory: string,
  product_getMultipleByBaseProduct: string,
  package_getMultiple: string,
  package_getMultipleExtended: string,
  shoppingList_get: string,
  shoppingList_getAll: string,
  shoppingList_create: string,
  shoppingList_clone: string,
  user_preAuth: string,
  user_auth: string
}

const endpoints: EndpointsList = {
  search: '/search',
  market_getAll: '/market',
  category_getAll: '/category',
  category_get: '/category/:categoryId',
  category_getFullHierarchy: '/category/:categoryId/full-hierarchy',
  baseProduct_get: '/base-product/:baseProductId',
  product_get: '/product/:productId',
  product_getMultipleByCategory: '/product/by-category/:categoryId',
  product_getMultipleByBaseProduct: '/product/by-base-product/:baseProductId',
  package_getMultiple: '/package/by-ids',
  package_getMultipleExtended: '/package/by-ids/extended',
  shoppingList_get: '/shopping-list/:shoppingListUuid',
  shoppingList_getAll: '/shopping-list',
  shoppingList_create: '/shopping-list',
  shoppingList_clone: '/shopping-list/:shoppingListUuid/clone',
  user_preAuth: '/user/pre-auth',
  user_auth: '/user/auth'    
}

export default endpoints