import Price from '../entities/Price'
import { getDaysDifference, getPriceDiscountExpirationDateString } from '../utils/DateManager'

export const isPromotion = (price: Price): boolean => {
  return !!price.discount || !!price.membership || price.count > 1
}

export const isOutdated = (price: Price): boolean => {
  return getDaysDifference(new Date(price.updatedAt), new Date()) > 7
}

export const getPriceDetails = (price: Price): string => {
  let details = ''

  if (isPromotion(price) && !isOutdated(price)) {
    let suffix = ''
    if (price.membership) {
      suffix += 'с ' + price.membership.name
    }
    if (price.until) {
      suffix += ' ' + getPriceDiscountExpirationDateString(new Date(price.until))
    }
    if (suffix.length === 0 && price.count === 1) {
      suffix = 'отстъпка'
    }

    if (price.oldPrice) {
      details = '- ' + price.discount + '% ' + suffix
    } else if (price.count > 1) {
      details = price.count + ' на цената на 1 ' + suffix
    } else {
      details = 'Промоция'
    }
  }

  return details
}

export const getSelectionPrice = (price: Price, count: number): number => {
  const effectiveCount = getEffectiveCount(price, count)
  return price.price * effectiveCount
}

const getEffectiveCount = (price: Price, count: number): number => {
  return Math.floor(count / price.count) + count % price.count
}