import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from './store'
import Product, {ProductPackage} from '../entities/Product'
import {
  buildCombinations,
  ShoppingListCombination,
  ShoppingListProducts, sortCombinations
} from '../services/ShoppingListManager'

const PRICES_TTL_IN_MILLIS = 6 * 60 * 60 * 1000

interface ProductPayload {
  product: Product,
  package: ProductPackage
}
export interface ShoppingListState {
  products: ShoppingListProducts
  combinations: {[key: string]: ShoppingListCombination},
  pricesLastUpdatedAt: string | null
}

const initialState: ShoppingListState = {
  products: {},
  combinations: {},
  pricesLastUpdatedAt: null
}

export const buildKey = (productId: number, packageId: number) => productId + ':' + packageId

const buildKeyFromPayload = (payload: ProductPayload) => buildKey(payload.product.id, payload.package.id)

export const shoppingListSlice = createSlice({
  name: 'shoppingList',
  initialState,
  reducers: {
    addProduct: (state: ShoppingListState, action:PayloadAction<ProductPayload>) => {
      if (Object.keys(state.products).length === 0) {
        state.pricesLastUpdatedAt = new Date().toISOString()
      }

      state.products[buildKeyFromPayload(action.payload)] = {
        product: action.payload.product,
        package: action.payload.package,
        count: 1
      }

      state.combinations = buildCombinations(state.products)
    },
    increase: (state: ShoppingListState, action: PayloadAction<ProductPayload>) => {
      state.products[buildKeyFromPayload(action.payload)].count += 1
      state.combinations = buildCombinations(state.products)
    },
    decrease: (state: ShoppingListState, action: PayloadAction<ProductPayload>) => {
      const key = buildKeyFromPayload(action.payload)
      const newCount = state.products[key].count - 1

      if (newCount <= 0) {
        delete state.products[key]
      } else {
        state.products[key].count = newCount
      }

      state.combinations = buildCombinations(state.products)
    },
    updatePackages: (state: ShoppingListState, action: PayloadAction<ProductPackage[]>) => {
      action.payload.forEach(pp => {
        const key = [pp.productId, pp.id].join(':')
        state.products[key].package = pp
      })
      state.combinations = buildCombinations(state.products)
      state.pricesLastUpdatedAt = new Date().toISOString()
    },
    reset: (state: ShoppingListState) => {
      state.products = initialState.products
      state.combinations = initialState.combinations
      state.pricesLastUpdatedAt = initialState.pricesLastUpdatedAt
    }
  }
})

export const { addProduct, increase, decrease, updatePackages, reset } = shoppingListSlice.actions
export const isInTheList = (state: RootState, productId: number, packageId: number): boolean => {
  return buildKey(productId, packageId) in state.shoppingList.products
}
export const areInTheList = (state: RootState, productId: number, packageIds: number[]): {[key: number]: boolean} => {
  const availabilityMap: {[key: number]: boolean} = {}
  for (let packageId of packageIds) {
    availabilityMap[packageId] = buildKey(productId, packageId) in state.shoppingList.products
  }
  return availabilityMap
}
export const isAnyInTheList = (state: RootState, productId: number, packageIds: number[]): boolean => {
  for (let packageId of packageIds) {
    if (buildKey(productId, packageId) in state.shoppingList.products) {
      return true
    }
  }
  return false
}
export const getShoppingListProducts = (state: RootState): ShoppingListProducts => {
  return state.shoppingList.products
}
export const getCount = (state: RootState, productId: number, packageId: number): number => {
  return state.shoppingList.products[buildKey(productId, packageId)].count || 0
}
export const getCombinations = (state: RootState): {[key: string]: ShoppingListCombination} => {
  return state.shoppingList.combinations
}
export const getSortedCombinationsKeysPriceAsc = (state: RootState): string[] => {
  return sortCombinations(Object.values(state.shoppingList.combinations))
}
export const getPriceSum = (state: RootState): number => {
  const sums = Object.values(state.shoppingList.combinations).map(combination => combination.sum)
  return sums.length > 0 ? parseFloat(Math.min(...sums).toFixed(2)) : 0
}
export const shouldUpdatePrices = (state: RootState): boolean => {
  const ttl = new Date().getTime() - PRICES_TTL_IN_MILLIS
  return !state.shoppingList.pricesLastUpdatedAt || new Date(state.shoppingList.pricesLastUpdatedAt).getTime() < ttl
}

export default shoppingListSlice.reducer
