import Product, {ProductPackage} from '../entities/Product'
import { getSelectionPrice } from './PriceManager'

const MAX_COMBINATION_LENGTH = 3

export type ShoppingListEntity = {
    product: Product,
    package: ProductPackage,
    count: number
}

export type ShoppingListProducts = {[key: string]: ShoppingListEntity}

export type ShoppingListCombination = {
    key: string
    productsByMarket: {[key: number]: string[]}
    sum: number
    sumByMarket: {[key: number]: number}
}

export type SavedShoppingListEntity = {
    // productId: number,
    packageId: number,
    count: number
}

export type SavedShoppingList = {
    markets: number[]
    products: {[key: number]: SavedShoppingListEntity[]}
}

export interface SavedShoppingListInfo extends SavedShoppingList {
    uuid: string
    createdAt: string
}

export const buildCombinations = (products: ShoppingListProducts, allowedMarkets: number[] = []): {[key: string]: ShoppingListCombination} => {
    const marketIds = getUniqueMarketIds(products, allowedMarkets)
    const marketCombinations = getAllMarketCombinations(marketIds)
    const marketToProductsMap = buildMarketToProductsMap(products)
    const validMarketCombinations = filterValidMarketCombinations(marketCombinations, marketToProductsMap, Object.keys(products).length)
    const cheapestMarketsCombination = findCheapestMarketsCombination(products, marketIds)
    const optimalMarketCombinations = findOptimalMarketCombinations(validMarketCombinations, cheapestMarketsCombination)
    return buildShoppingLists(products, optimalMarketCombinations)
}

const getUniqueMarketIds = (products: ShoppingListProducts, allowedMarkets: number[] = []): number[] => {
    let marketIds = new Set<number>();
    for (const entityId in products) {
        products[entityId].package.prices
            .map(price => price.marketId)
            // no allowedMarkets === all markets are allowed
            .filter(marketId => allowedMarkets.length === 0 || allowedMarkets.indexOf(marketId) >= 0)
            .forEach(marketIds.add, marketIds)
    }
    return [...marketIds]
}

const getAllMarketCombinations = (marketIds: number[]): number[][] => {
    const result: number[][] = []

    function generateCombinations(current: number[], start: number): void {
        result.push([...current])

        for (let i = start; i < marketIds.length; i++) {
            current.push(marketIds[i])
            generateCombinations(current, i + 1)
            current.pop()
        }
    }

    generateCombinations([], 0)

    return result.filter(combination => combination.length > 0)
}

const buildMarketToProductsMap = (products: ShoppingListProducts): {[key: number]: string[]} => {
    let map: {[key: number]: string[]} = {}

    for (const entityId in products) {
        products[entityId].package.prices.map(price => price.marketId).forEach(marketId => {
            if (!map.hasOwnProperty(marketId)) {
                map[marketId] = []
            }
            map[marketId].push(entityId)
        })
    }

    return map
}

const filterValidMarketCombinations = (
    marketCombinations: number[][],
    marketToProductsMap: {[key: number]: string[]},
    allProductsCount: number
): number[][] => {
    let validMarketCombinations: number[][] = []

    for (const combination of marketCombinations) {
        let products = new Set<string>()
        combination.flatMap(marketId => marketToProductsMap[marketId]).forEach(products.add, products)

        if (products.size === allProductsCount) {
            validMarketCombinations.push(combination)
        }
    }

    return validMarketCombinations.sort((a, b) => a.length - b.length)
}

const findCheapestMarketsCombination = (products: ShoppingListProducts, marketIds: number[]): number[] => {
    let cheapestMarkets = new Set<number>()

    for (const entityId in products) {
        cheapestMarkets.add([...products[entityId].package.prices]
            .filter(price => marketIds.indexOf(price.marketId) >= 0)
            .sort((a, b) => a.price - b.price)
            .map(price => price.marketId)[0]
        )
    }

    return [...cheapestMarkets]
}

const findOptimalMarketCombinations = (validMarketCombinations: number[][], cheapestMarketsCombination: number[]): number[][] => {
    const maxCombinationLength = Math.min(cheapestMarketsCombination.length - 1, MAX_COMBINATION_LENGTH)

    let optimalMarketCombinations = []
    if (cheapestMarketsCombination.length > 0 && cheapestMarketsCombination.length <= MAX_COMBINATION_LENGTH) {
        optimalMarketCombinations.push(cheapestMarketsCombination)
    }

    return [
        ...optimalMarketCombinations,
        ...validMarketCombinations.filter(combination => combination.length <= maxCombinationLength)
    ]
}

const buildShoppingLists = (
    products: ShoppingListProducts,
    optimalMarketCombinations: number[][]
): {[key: string]: ShoppingListCombination} => {
    let combinations: {[key: string]: ShoppingListCombination} = {}

    for (const marketsCombination of optimalMarketCombinations) {
        const marketsCombinationKey = marketsCombination.join('-')
        combinations[marketsCombinationKey] = {
            key: marketsCombinationKey,
            productsByMarket: {},
            sum: 0,
            sumByMarket: {}
        }

        for (const entityId in products) {
            const lowestPrice = products[entityId].package.prices
                .filter(price => marketsCombination.indexOf(price.marketId) >= 0)
                .sort((a, b) => a.price - b.price)[0]
            const price = getSelectionPrice(lowestPrice, products[entityId].count)

            if (!combinations[marketsCombinationKey].productsByMarket.hasOwnProperty(lowestPrice.marketId)) {
                combinations[marketsCombinationKey].productsByMarket[lowestPrice.marketId] = []
            }

            if (!combinations[marketsCombinationKey].sumByMarket.hasOwnProperty(lowestPrice.marketId)) {
                combinations[marketsCombinationKey].sumByMarket[lowestPrice.marketId] = 0
            }

            combinations[marketsCombinationKey].productsByMarket[lowestPrice.marketId].push(entityId)
            combinations[marketsCombinationKey].sum += price
            combinations[marketsCombinationKey].sumByMarket[lowestPrice.marketId] += price
        }
    }

    return combinations
}

export const sortCombinations = (combinations: ShoppingListCombination[]): string[] => {
    return combinations
        .sort((a, b) => a.sum - b.sum)
        .map(c => c.key)
}