import React, { Fragment, useState } from 'react'
import './MobileMenu.scss'
import { Link } from 'react-router-dom'
import { Divider, Drawer, ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material'
import Box from '@mui/material/Box'
import KeyboardArrowRightRoundedIcon from '@mui/icons-material/KeyboardArrowRightRounded'
import SettingsIcon from '@mui/icons-material/Settings'
import { getLinkToCategory } from '../../routing/routes'
import MenuItem from '@mui/material/MenuItem'
import PersonAdd from '@mui/icons-material/PersonAdd'
import Logout from '@mui/icons-material/Logout'
import { isLoggedIn, removeToken } from '../../redux/authSlice'
import { useAppDispatch, useAppSelector } from '../../redux/hooks'
import { Login as LoginIcon } from '@mui/icons-material'
import Login from '../common/login/Login'
import { useCategories } from '../../contexts/BasicData'

interface MobileMenuProps {
  isOpen: boolean
  toggleMenu: (open: boolean) => (e: React.KeyboardEvent | React.MouseEvent) => void
}

export default function MobileMenu(props: MobileMenuProps) {
  const categories = useCategories()
  const dispatch = useAppDispatch()
  const loggedIn = useAppSelector(isLoggedIn)
  const [openLoginDialog, setOpenLoginDialog] = useState(false)
  const [categoryOpen, setCategoryOpen] = useState<{ [key: string]: boolean }>({})

  const toggleCategory = (id: string, open?: boolean) => {
    setCategoryOpen(prevState => ({
      ...prevState,
      [id]: open != null ? open : !prevState[id]
    }))
  }

  const logout = () => {
    dispatch(removeToken())
  }

  const close = (e: React.KeyboardEvent | React.MouseEvent) => {
    categories.forEach(c => toggleCategory(c.id.toString(), false))
    props.toggleMenu(false)(e)
  }

  return (
    <Fragment>
      <Drawer
        anchor="left"
        open={props.isOpen}
        onClose={close}
      >
        <Box
          className="mobile-menu"
          sx={{ width: 280 }}
          role="presentation"
        >
          <ListItem key="categories-header" className="group-header">Категории</ListItem>
          {categories.map(category => (
            <Box
              key={'m_cat_' + category.id}
              className="mobile-menu-categories"
              sx={{ width: 280 }}
              role="presentation"
            >
              <ListItem disablePadding>
                <ListItemButton onClick={() => toggleCategory(category.id.toString())}>
                  <ListItemIcon className="icon"><KeyboardArrowRightRoundedIcon
                  sx={[
                    {
                      transition: '0.2s',
                    },
                    categoryOpen[category.id.toString()]
                      ? {
                          transform: 'rotate(90deg)',
                        }
                      : {
                          transform: 'rotate(0)',
                        },
                  ]} /></ListItemIcon>
                  <ListItemText primary={category.name} />
                </ListItemButton>
              </ListItem>
              {categoryOpen[category.id.toString()] && category.children?.map(c => (
                <Link key={'m_cat_' + c.id} to={getLinkToCategory(c, false)} onClick={close}>
                  <ListItemButton>
                    <ListItemText className="subcategory" primary={c.name} />
                  </ListItemButton>
                </Link>
              ))}
            </Box>
          ))}

          <Divider />
          <ListItem key="profile-header" className="group-header">Профил</ListItem>
          {loggedIn ?
            <Fragment>
              {/*<ListItem key="profile-city-stores" disablePadding>*/}
              {/*    <ListItemButton>*/}
              {/*        <ListItemIcon className="icon"><SettingsIcon /></ListItemIcon>*/}
              {/*        <Link to="/настройки" className="category-link">*/}
              {/*            <ListItemText primary="Град и магазини" />*/}
              {/*        </Link>*/}
              {/*    </ListItemButton>*/}
              {/*</ListItem>*/}
              <ListItem key="profile-saved-shopping-lists" disablePadding>
                <ListItemButton>
                  <ListItemIcon className="icon"><PersonAdd /></ListItemIcon>
                  <Link to="/saved-lists">
                    <ListItemText primary="Списъци за пазарене" />
                  </Link>
                </ListItemButton>
              </ListItem>
              <ListItem key="profile-logout" disablePadding onClick={logout}>
                <ListItemButton>
                  <ListItemIcon className="icon"><Logout /></ListItemIcon>
                  <ListItemText primary="Изход" />
                </ListItemButton>
              </ListItem>
              {/*<ListItem key="settings-profile" disablePadding>*/}
              {/*    <ListItemButton>*/}
              {/*        <ListItemIcon className="icon"><PersonRoundedIcon /></ListItemIcon>*/}
              {/*        <Link to="/профил" className="category-link">*/}
              {/*            <ListItemText primary="Профил" />*/}
              {/*        </Link>*/}
              {/*    </ListItemButton>*/}
              {/*</ListItem>*/}
            </Fragment> :
            <Fragment>
              <ListItem key="profile-logout" disablePadding onClick={() => setOpenLoginDialog(true)}>
                <ListItemButton>
                  <ListItemIcon className="icon"><LoginIcon /></ListItemIcon>
                  <ListItemText primary="Вход" />
                </ListItemButton>
              </ListItem>
            </Fragment>
          }
        </Box>
      </Drawer>
      {openLoginDialog ? <Login onClose={() => setOpenLoginDialog(false)} /> : ''}
    </Fragment>
  )
}