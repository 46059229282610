const DAY_IN_MILLIS = 1000 * 3600 * 24

export const getDaysDifference = (a: Date, b: Date): number => {
  // ignore time part
  const dateA = new Date(a.getFullYear(), a.getMonth(), a.getDate())
  const dateB = new Date(b.getFullYear(), b.getMonth(), b.getDate())
  return Math.round((dateB.getTime() - dateA.getTime()) / DAY_IN_MILLIS)
}

export const getPriceDiscountExpirationDateString = (date: Date): string => {
  const today = new Date()
  const daysDifference = getDaysDifference(today, date)

  if (daysDifference < 0) {
    return 'изтекла'
  } else if (daysDifference === 0) {
    return 'само днес'
  } else if (daysDifference < 5) {
    return 'до ' + getWeekDay(date.getDay() + 1)
  } else if (daysDifference < 14) {
    return 'още ' + daysDifference + ' дни'
  } else {
    return 'до ' + dayToString(date.getDate()) + ' ' + monthToString(date.getMonth() + 1, false)
  }
}


export const getCreationDateString = (date: Date): string => {
  const today = new Date()
  const daysDifference = getDaysDifference(today, date)

  console.log('daysDifference', today, date, date.toUTCString(), daysDifference)
  if (daysDifference > 0) {
    return '' // in the future
  } else if (daysDifference === 0) {
    return 'днес'
  } else if (daysDifference === 1) {
    return 'вчера'
  } else if (daysDifference > -7) {
    console.log('getWeekDay', date.getDay() + 1)
    return getWeekDay(date.getDay() + 1)
  } else {
    return dayToString(date.getDate()) + ' ' + monthToString(date.getMonth() + 1, true)
  }
}

const dayToString = (day: number) => {
  return day + getDaySuffix(day)
}

const getDaySuffix = (day: number): string => {
  switch (day % 20) {
    case 1:
      return '-ви'
    case 2:
      return '-ри'
    case 3:
    case 4:
    case 5:
    case 6:
    case 9:
    case 0:
    case 10:
    case 11:
    case 12:
    case 13:
    case 14:
    case 15:
    case 16:
    case 17:
    case 18:
    case 19:
      return '-ти'
    case 7:
    case 8:
      return '-ми'
    default:
      return ''
  }
}

const monthToString = (month: number, fullName: boolean): string => {
  switch (month) {
    case 1:
      return fullName ? 'януари' : 'яну'
    case 2:
      return fullName ? 'февруари' : 'фев'
    case 3:
      return fullName ? 'март' : 'мар'
    case 4:
      return fullName ? 'април' : 'апр'
    case 5:
      return 'май'
    case 6:
      return 'юни'
    case 7:
      return 'юли'
    case 8:
      return fullName ? 'август' : 'авг'
    case 9:
      return fullName ? 'септември' : 'сеп'
    case 10:
      return fullName ? 'октомври' : 'окт'
    case 11:
      return fullName ? 'ноември' : 'ное'
    case 12:
      return fullName ? 'декември' : 'дек'
    default:
      return ''
  }
}

const getWeekDay = (day: number): string => {
  switch (day) {
    case 1:
      return 'понеделник'
    case 2:
      return 'вторник'
    case 3:
      return 'сряда'
    case 4:
      return 'четвъртък'
    case 5:
      return 'петък'
    case 6:
      return 'събота'
    case 7:
      return 'неделя'
    default:
      return ''
  }
}