import React, { createContext, ReactNode, useContext, useEffect, useState } from 'react'
import { Market, Store } from '../entities/Market'
import { ProductsListViewLoader } from '../components/loaders/ProductsListViewLoader'
import useApi from '../api/request'
import endpoints from '../api/endpoints'
import { Category } from '../entities/Category'

const MarketsContext = createContext<Market[]>([])
const CategoriesContext = createContext<Category[]>([])

interface BasicDataProviderProps {
  children?: ReactNode
}
export function BasicDataProvider({ children }: BasicDataProviderProps) {
  const [markets, setMarkets] = useState<Market[]>([]);
  const [categories, setCategories] = useState<Category[]>([])
  const [getMarkets, { loading }] = useApi(endpoints.market_getAll, {
    onCompleted: (data: Market[]) => {
      setMarkets(data)
    }
  })
  const [getCategories] = useApi(endpoints.category_getAll, {
    onCompleted: (data: Category[]) => setCategories(data)
  })

  useEffect(() => {
    getMarkets()
    getCategories()
  }, [])

  if (loading) {
    // fixme use appropriate loader
    return (
      <ProductsListViewLoader aside={false} />
    )
  }

  return (
    <MarketsContext.Provider value={markets} >
      <CategoriesContext.Provider value={categories}>
        {children}
      </CategoriesContext.Provider>
    </MarketsContext.Provider>
  )
}

export const useMarkets = () => useContext(MarketsContext)
export const useCategories = () => useContext(CategoriesContext)

export const getMarketById = (id: number, markets: Market[]): Market => {
  return (markets.find(market => market.id === id) as Market)
}

export const getMarketByName = (name: string, markets: Market[]): Market => {
  return (markets.find(market => market.name === name) as Market)
}

export const getStoreById = (id: number, markets: Market[]): Store => {
  return (markets.flatMap(market => market.stores).find(store => store.id === id) as Store)
}